<template>
  <div class="topnav d-flex justify-content-start">
    <router-link active-class="active" class="online-link p-2" :to="{name : 'Receipts'}">
      Validées
    </router-link>
    <router-link active-class="active" class="hold-link p-2" :to="{name : 'Onhold'}">
      En attente
    </router-link>
  </div>
</template>
<style scoped>
.topnav a {
  color: black;
  text-align: center;
  text-decoration: none;
  font-size: 17px;
  border-bottom: 3px solid transparent;
}

.topnav .hold-link:hover {
  border-bottom: 3px solid #f0ad4e;
  color: #f0ad4e;
}
.topnav .online-link:hover {
  border-bottom: 3px solid #5cb85c;
  color: #f0ad4e;
}

.topnav .hold-link.active {
  border-bottom: 3px solid #f0ad4e;
  color: #f0ad4e;
}
.topnav .online-link.active {
  border-bottom: 3px solid #5cb85c;
  color: #5cb85c;
}
</style>