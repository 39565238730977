<template>
  <base-loading v-if="isLoading" />
  <div class="container-fluid" v-else>
    <div class="row">
      <div class="col-9 col-md-7 col-lg-9">
        <!-------- Filter---------------->
        <div class="row mt-3 d-flex justify-content-end mb-2 p-1">
          <div class="col-12">
            <base-breadcrumb :pageName="currentPageName" :storeName="storeName" />
          </div>
          <div class="col-4 col-lg-3">
            <app-switch-receipt />
          </div>
          <div class="col-12 col-lg-4 d-flex justify-content-end">
            <base-search
              @search-event="searchThrowReceipt"
              @reset="allReceipt"
              :showIcon="true"
              :placeholder="'Rechercher par Référence'"
            />
          </div>
        </div>
        <!-------- Filter Ends---------------->
        <div class="row card-container">
          <div class="container">
            <div class="card card-table p-4">
              <div class="row">
                <table
                  style="font-size: 15px"
                  class="table global-table table-striped table-hover table-sm bg-white table-reponsive border-none"
                >
                  <thead>
                    <tr>
                      <th>Référence</th>
                      <th>Client</th>
                      <th>Date</th>
                      <th>Articles</th>
                      <th>Montant</th>
                      <th>Status</th>
                      <th class="text-center"></th>
                    </tr>
                  </thead>
                  <tbody v-if="isLoadingReceipts">
                    <tr class="mt-2">
                      <td colspan="12" style="height: 22rem">
                        <base-grow-loading style="margin-top: 7rem" />
                      </td>
                    </tr>
                  </tbody>
                  <tbody v-else-if="!isLoadingReceipts && receipts.length">
                    <app-receipt-composition
                      v-for="receipt in receipts"
                      :key="receipt.id"
                      :receipt="receipt"
                      :selectedReceiptId="selectedReceipt ? selectedReceipt.id : 0"
                      :confirmDelete="confirmDelete"
                      :updateSelectedReceipt="updateSelectedReceipt"
                      :user_has_access_to_delete="user_has_access_to_delete"
                    />
                  </tbody>
                  <tbody v-else>
                    <tr class="mt-2">
                      <td colspan="12">
                        <base-empty-result />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="row d-flex justify-content-end">
                <paginate
                  v-if="pageCount > 1"
                  v-model="page"
                  :page-count="pageCount"
                  :page-range="3"
                  :margin-pages="2"
                  :click-handler="paginate"
                  :prev-text="'Précédent'"
                  :next-text="'Suivant'"
                  :container-class="'pagination'"
                  :page-class="'page-item'"
                >
                </paginate>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-------- Selected Receipt listing  starts---------------->
      <div
        class="col-3 col-md-5 col-lg-3 bg-white shadow-sm"
        v-if="selectedReceipt && !receiptIsLoading"
      >
        <app-receipt
          :order="selectedReceipt"
          :in_submition="update_in_submition"
          @print-receipt="printReceipt()"
          @updateReceipt="updateReceipt"
        />

        <!-- Receipt to print -->
        <div v-show="false">
          <div id="print-frame" style="margin: 0; padding: 0; heigth: 50% !important">
            <app-receipt
              v-if="showReceipt"
              :order="selectedReceipt"
              :hideButtons="true"
              :showLogo="true"
            />
          </div>
        </div>
      </div>
      <div v-else>
        <base-grow-loading style="margin-top: 15rem; margin-left: 7.98rem" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapActions, mapGetters, mapState } from "vuex";
import mixins from "@/includes/mixins";
import Paginate from "vuejs-paginate-next";
import AppReceipt from "@/views/receipt/PosReceipt";
import AppReceiptComposition from "@/views/receipt/ReceiptComposition";
import AppSwitchReceipt from "@/views/receipt/SwitchReceipt";
import ReceiptService from "@/services/receipt.service";
import CashierService from "@/services/cashier.service";
const cashierService = new CashierService();
const receiptService = new ReceiptService();

export default {
  mixins: [mixins],
  components: {
    Paginate,
    AppReceipt,
    AppReceiptComposition,
    AppSwitchReceipt,
  },
  data() {
    return {
      receipts: [],
      page: 1,
      selectedReceipt: null,
      per_page: 0,
      pageCount: 0,
      isLoading: false,
      isLoadingReceipts: false,
      receiptIsLoading: false,
      isRenderd: false,
      currentPageName: "Liste des ventes",
      storeName: null,
      user_has_access_to_delete: false,
      storeId: null,
      reCheckoutProduct: false,
      update_in_submition: false,
    };
  },
  async created() {
    this.isLoading = true;
    this.storeId = this.getApiData.settings.store.id;
    this.paginate();
    this.user_has_access_to_delete = this.getApiData.settings.permissions[
      "pos.receipt.delete"
    ];
    this.isLoading = false;
  },
  computed: {
    ...mapGetters(["getApiData"]),
    ...mapState({
         showReceipt: (state) => state.receipt.showReceipt,
    }),
    validOrder() {
      let discount = {
        type: this.selectedReceipt.discount_type,
        value: this.selectedReceipt.discount_value,
        amount: this.selectedReceipt.discount_amount,
        percentage: this.selectedReceipt.discount_percent,
      };
      let lines = this.selectedReceipt.lines;
      lines = lines.map((line) => {
        return {
          ...line,
          id: line.linkedid,
          totalLine: this.numberFormat(this.getLineTotal(line), "."),
          discountValue: this.singleLineDiscountValue(line),
          discount_percentage: this.numFormat(line.discount * 100),
        };
      });
      return {
        created: this.selectedReceipt.created,
        reference: this.selectedReceipt.reference,
        total: this.selectedReceipt.amount,
        total_tax: this.selectedReceipt.total_tax,
        items: lines,
        customer: { id: this.selectedReceipt.accountid },
        payment_method: this.selectedReceipt.payment_method,
        bankid: this.selectedReceipt.bankid,
        accountid: this.selectedReceipt.accountid,
        discount,
      };
    },
  },

  methods: {
    ...mapActions(["updateOrder", "clearCart"]),
    ...mapMutations(["toggleShowReceipt"]),
    async paginate() {
      this.isLoadingReceipts = true;
      this.toggleShowReceipt(false);
      try {
        let data = {
          q: {
            storeid: {
              equals: this.storeId,
            },
          },
        };
        const res = await receiptService.get(this.page, data);
        this.per_page = res.data.itemsPerPage;
        this.pageCount = res.data.pages;
        this.receipts = res.data.data;
        if (this.receipts.length) {
          this.getReceipt(this.receipts[0].lines[0].receipt_id);
        }
        setTimeout(() => {
          window.scrollTo(0, 0);
        }, 1000);
      } catch (err) {
        console.log(err);
      }
      this.isLoadingReceipts = false;
    },

    async searchThrowReceipt(keyword) {
      if (keyword == "") {
        this.paginate();
        return;
      }
      try {
        const res = await receiptService.search(keyword);
        this.receipts = res.data.data;
        if (this.receipts.length) {
          this.getReceipt(this.receipts[0].id);
        }
        this.pageCount = Math.ceil(this.receipts.length / this.per_page);
      } catch (err) {
        console.log(err);
      }
    },

    updateReceipt() {
      this.update_in_submition = true;
      this.confirmDelete(this.selectedReceipt.id, { name: "Pos" });
    },

    async deleteReceipt(id, redirectPath = null) {
      try {
        const res = await receiptService.delete(id);
        if (redirectPath) {
          this.reCheckoutProduct = true;
          this.$toast.success("Operation enregistré", {
            position: "top-right",
            timeout: 5000,
          });
          this.updateOrder(this.validOrder);
          this.$router.push(redirectPath);
          return;
        }
      } catch (err) {
        this.$swal.fire("Supprimé!", "cet vent n'a pas été supprimé.", "error");
      }
      this.update_in_submition = false;
    },

    confirmDelete(id, redirectPath = null) {
      this.$swal
        .fire({
          title: "Vous ne pourrez pas revenir en arrière!",
          text: "Les mouvements de stock liés à cette vente seront également supprimés !",
          icon: "warning",
          showCancelButton: true,
          cancelButtonText: "Annuler",
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Confirmer ",
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.deleteReceipt(id, redirectPath);
          } else {
            this.update_in_submition = false;
          }
        });
    },

    allReceipt() {
      this.paginate();
    },

    async getReceipt(id) {
      try {
        this.receiptIsLoading = true;
        let response = await receiptService.getReceipt(id);
        this.selectedReceipt = response.data;
        this.updateOrder(this.validOrder);
        this.receiptIsLoading = false;
      } catch (error) {
        console.log(error);
      }
      this.isLoading = false;
    },

    updateSelectedReceipt(id) {
      this.getReceipt(id);
    },

    async printReceipt() {
      let selectedReceipt = structuredClone(this.selectedReceipt);
      let lines = (lines = selectedReceipt.lines.map((line) => {
        return {
          ...line,
          id: line.linkedid,
          totalLine: this.numberFormat(this.getLineTotal(line), "."),
          discountValue: this.singleLineDiscountValue(line),
          discount_percentage: this.numFormat(line.discount),
        };
      }));
      selectedReceipt.lines = lines;
      this.printReceiptProtocol(selectedReceipt);
    },
  },

  beforeRouteLeave() {
    if (!this.reCheckoutProduct) {
      this.clearCart();
    }
  },
};
</script>
<style scoped lang="scss">
.p-datatable-tbody {
  tr {
    color: red !important;
  }
}
.p-datatable-tbody:hover {
  background-color: blue;
}
#product-list-body #product-list-column {
  height: 300px;
}
#product-list-body #product-list-column {
  overflow: hidden;
  overflow-y: scroll;
}
img {
  padding: auto;
  height: 40px;
  width: 40px;
}
.table-container {
  height: 600px;
  overflow: scroll;
}
.table-container thead tr:hover {
  background: rgb(209, 229, 243);
}

.selected- {
  border: none;
  background: #efefef;
  -webkit-transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease;
}
.card-container {
  overflow-y: scroll;
  height: 500px;
}
.card-container .card {
  border-radius: 15px;
}
.selected-receipt,
.receipt-item:hover {
  border-radius: 15px;
  background-color: rgb(241, 244, 246);
}
.tab {
  padding: 10px 20px;
}
.table th {
  padding: 1rem;
}
</style>
