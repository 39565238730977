<template>
  <div class="container">
    <div class="header">
      <div class="col-12 p-3" v-if="mode === 'hold'">
        <h5>
          Info:
          <span class="text-warning">{{ selectedReceipt.name }}</span>
        </h5>
        <div class="row ticket-items" id="product-list-column">
          <div
            class="col-md-12 mt-1 d-flex justify-content-between rounded"
            v-for="item in selectedReceipt.lines"
            :key="item.id"
          >
            <div class="d-flex justify-content-start">
              <div class="ml-2 mr-4">
                {{ item.name }}
                <div>{{ numFormat(item.price) }} X {{ item.quantity }}</div>
              </div>
              <div class="text-right">
                <strong> {{ numFormat(item.price * item.quantity) }}</strong>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Receipt from "@/views/receipt/PosReceipt.vue";

export default {
  name: "Ticket",
  props: ["selectedReceipt", "mode", "in_submition"],
  components: {
    Receipt,
  },
  computed: {
    ...mapState({
      showReceipt: (state) => state.receipt.showReceipt,
    }),
  },
  methods: {
    getSubTotal(items) {
      return _.sumBy(items, function (item) {
        return _.multiply(item.price, item.quantity);
      });
    },
    numFormat(number) {
      return this.$filters.numFormat(number);
    },
  },
};
</script>
<style scoped>
.header {
  margin-top: 20px;
}
.ticket-header {
  font-size: 20px;
}
.ticket-items {
  height: 300px;
  overflow-y: scroll;
  overflow-x: hidden;
}
.receipt-icon {
  font-size: 30px;
}
#receipt-view-container {
  max-height: 550px;
  overflow-y: scroll;
}
.receipt:hover {
  transform: scale(0.99);
}
</style>
