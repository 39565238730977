import http from "./http.service";

class CashierService {
    get() {
        return http.get("/pos/cash");
    }

    store(data) {
        return http.post("/pos/cash", data);
    }

    update(data, id) {
        return http.put("/pos/cash/" + id, data);
    }

    delete(id) {
        return http.delete("/pos/cash/" + id);
    }

    getBalance(id) {
        return http.get("/pos/cash-balance/" + id);
    }
}

export default CashierService;