<template>
  <tr
    class="col-md-12 receipt-item border-bottom mt-1"
    :class="{
      'selected-receipt': receipt.id == selectedReceiptId,
    }"
    @click.prevent="updateSelectedReceipt(receipt.id)"
  >
    <td>{{ receipt.reference }}</td>
    <td>{{ receipt.account ? receipt.account.name : '' }}</td>
    <td class="text-right">{{ receipt.displayed_date }}</td>
    <td class="text-right">{{ itemsCount }}</td>
    <td class="text-right">{{ receipt.amount }} {{ receipt.currency }}</td>
    <td>
      <span
        :class="`badge badge-pill badge-${receipt.step_details.label} w-100 p-1`"
        >{{ receipt.step_details.title }}</span
      >
    </td>
    <td>
      <button
        @click.prevent="updateSelectedReceipt(receipt.id)"
        class="btn btn-light border border-primary btn-sm text-primary mr-2"
      >
        <span>
          <i class="fas fa-eye"></i>
        </span>
      </button>
    </td>
  </tr>
</template>

<script>
import mixins from '@/includes/mixins';
export default {
  name: "ReceiptDetails",
  mixins: [mixins],
  props: {
    receipt: {
      type: Object,
      required: true,
    },
    user_has_access_to_delete: {
      type: Boolean,
      required: true
    },
    selectedReceiptId: {
      type: Number,
      required: true,
    },
    confirmDelete: {
      type: Function,
      required: true,
    },
    updateSelectedReceipt: {
      type: Function,
      required: true,
    },
  },
  computed: {
    itemsCount() {
      return _.sumBy(this.receipt.lines, function (item) {
        return item.quantity;
      });
    },
  },
};
</script>
