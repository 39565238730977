<template>
  <base-loading v-if="isLoading" />
  <div v-else>
    <div class="col-12 mt-3">
      <base-breadcrumb :pageName="currentPageName" :storeName="''" />
    </div>
    <div class="row justify-content-center">
      <div class="col-sm-12">
        <div class="container-fluid">
          <div class="row">
            <div class="col-sm-12" v-if="filteredHolds.length">
              <div class="row">
                <div class="col-md-8">
                  <!-------- Filter---------------->
                  <div class="row mt-3 d-flex justify-content-end mb-2">
                    <div class="col-4 col-lg-3">
                      <app-switch-receipt />
                    </div>
                    <div class="col-12 col-lg-4">
                      <base-search
                        @search-event="searchThrowHolds"
                        @reset="paginate"
                        placeholder="Rechercher par Référence"
                        :showIcon="true"
                      />
                    </div>
                  </div>
                  <!-------- Receipts Listing  Stars---------------->
                  <div class="row card-container">
                    <div class="container">
                      <div class="card p-4" v-if="filteredHolds.length">
                        <div class="row">
                          <div
                            @click="selectedHold = receipt"
                            :class="{
                              'selected-receipt':
                                selectedHold && receipt.date == selectedHold.date,
                            }"
                            v-for="receipt in filteredHolds"
                            :key="receipt.id"
                            class="col-md-12 receipt-item border-bottom mt-1"
                          >
                            <div class="row">
                              <div class="col-md-4 pt-2">
                                <strong>{{ receipt.name }}</strong>
                                <div class="text-muted">
                                  {{ receipt.customer.name }}
                                </div>
                              </div>
                              <div class="col-md-4 pt-2">
                                <strong
                                  >{{ numFormat(getTotalAmount(receipt.lines)) }}
                                  <span class="text-muted">{{
                                    receipt.currency
                                  }}</span></strong
                                >
                                <div class="text-muted">
                                  {{ getItemsCount(receipt.lines) }} articles
                                </div>
                              </div>
                              <div class="col-md-4 text-right pt-2">
                                <div class="text-muted">
                                  {{ dateFormat(receipt.date) }}
                                </div>
                                <div class="receipt-action-icons">
                                  <span
                                    @click="addToCart"
                                    type="button"
                                    class="btn btn-default text-warning btn-lg"
                                    ><i class="fa fa-shopping-cart"></i
                                  ></span>
                                  <span
                                    @click="deleteHold(receipt)"
                                    type="button"
                                    class="btn btn-default text-danger btn-lg"
                                    ><i class="fa fa-trash-alt"></i
                                  ></span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row d-flex justify-content-end mt-3">
                          <paginate
                            v-if="pageCount > 1"
                            v-model="page"
                            :page-count="pageCount"
                            :page-range="3"
                            :margin-pages="2"
                            :click-handler="paginate"
                            :prev-text="'Précédent'"
                            :next-text="'Suivant'"
                            :container-class="'pagination'"
                            :page-class="'page-item'"
                          >
                          </paginate>
                        </div>
                      </div>
                      <base-empty-result v-else />
                    </div>
                  </div>
                </div>
                <!-------- Receipt Ends---------------->

                <!-------- Selected Receipt listing  starts---------------->
                <div class="col-md-4 shadow-sm bg-white" v-if="filteredHolds.length">
                  <Ticket
                    mode="hold"
                    :selectedReceipt="selectedHold"
                    @addtocart-event="addToCart"
                  ></Ticket>
                </div>
              </div>
            </div>
            <div class="col-12 p-4" v-else>
              <base-empty-result />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import moment from "moment";
import { mapActions } from "vuex";
import Paginate from "vuejs-paginate-next";
import Ticket from "@/views/receipt/Ticket.vue";
import AppSwitchReceipt from "@/views/receipt/SwitchReceipt";

export default {
  components: {
    Paginate,
    Ticket,
    AppSwitchReceipt,
  },
  data() {
    return {
      isLoading: false,
      selectedHold: null,
      filteredHolds: null,
      holds: null,
      page: 1,
      per_page: 20,
      pageCount: 0,
      currentPageName: "Liste des Commandes en attente",
    };
  },
  created() {
    this.isLoading = true;
    setTimeout(() => {
      this.isLoading = false;
    }, 1000);
    if (localStorage.getItem("holdOrders")) {
      if (JSON.parse(localStorage.getItem("holdOrders")).length !== 0) {
        this.holds = JSON.parse(localStorage.getItem("holdOrders"));
        this.pageCount = Math.floor(this.holds.length / this.per_page + 1) - 1;
        this.paginate();
        this.selectedHold = this.holds[0];
        return;
      }
    }
    this.untialize();
  },
  methods: {
    ...mapActions(["updateOrder", "removeHoldOrder"]),
    paginate() {
      this.filteredHolds = this.holds
        .slice((this.page - 1) * this.per_page)
        .slice(0, this.per_page);
    },
    searchThrowHolds(keyword) {
      keyword = keyword.toLowerCase();
      if (keyword == "") {
        this.paginate();
        return;
      }
      let resByCustomerName = _.filter(this.holds, (o) =>
        _.includes(o.customer.name.toLowerCase(), keyword)
      );
      let resByName = _.filter(this.holds, (o) =>
        _.includes(o.name.toLowerCase(), keyword)
      );
      this.filteredHolds = _.unionBy(resByName, resByCustomerName, "id");
      this.pageCount = Math.floor(this.filteredHolds.length / this.per_page + 1);
    },
    getItemsCount(items) {
      return _.sumBy(items, function (item) {
        return item.quantity;
      });
    },
    getTotalAmount(items) {
      return _.sumBy(items, function (item) {
        return _.multiply(item.price, item.quantity);
      });
    },
    addToCart() {
      this.updateOrder({
        items: this.selectedHold.lines,
        customer: { id: this.selectedHold.customer.id },
        discount: this.selectedHold.discount,
      });
      this.removeHoldOrder(this.selectedHold.date);
      this.$router.push({ name: "Pos" });
    },
    untialize() {
      this.holds = [];
      this.filteredHolds = [];
      this.selectedHold = [];
    },
    deleteHold(receipt) {
      let filteredHoldsByDelete = _.filter(this.holds, function (o) {
        return o.date !== receipt.date;
      });
      localStorage.removeItem("holdOrders");
      localStorage.setItem("holdOrders", JSON.stringify(filteredHoldsByDelete));
      if (JSON.parse(localStorage.getItem("holdOrders")).length === 0) {
        this.untialize();
        localStorage.removeItem("holdOrders");
        return;
      }
      this.holds = filteredHoldsByDelete;
      this.filteredHolds = this.holds;
      this.selectedHold = this.holds[0];
      this.$toast.success("Vente supprimée", {
        position: "top-right",
        timeout: 5000,
      });
      this.paginate();
    },
    dateFormat(date) {
      moment.locale("fr");
      return moment(date).fromNow();
    },
    numFormat(number) {
      return this.$filters.numFormat(number);
    },
  },
};
</script>
<style scoped>
#container {
  display: table;
  width: 100%; /* not required, just for example */
  height: 100%; /* not required, just for example */
}

#update {
  font-size: 100px;
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}
/* width */
::-webkit-scrollbar {
  width: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(2, 117, 216);
}

#product-list-body #product-list-column {
  height: 300px;
}
#product-list-body #product-list-column {
  overflow: hidden;
  overflow-y: scroll;
}
img {
  padding: auto;
  height: 50px;
  width: 50px;
}
.topnav a {
  color: black;
  text-align: center;
  text-decoration: none;
  font-size: 17px;
  border-bottom: 3px solid transparent;
}

.topnav .hold-link:hover {
  border-bottom: 3px solid #f0ad4e;
}
.topnav .online-link:hover {
  border-bottom: 3px solid #5cb85c;
}

.topnav .hold-link.active {
  border-bottom: 3px solid #f0ad4e;
  color: #f0ad4e;
}
.topnav .online-link.active {
  border-bottom: 3px solid #5cb85c;
  color: #5cb85c;
}
.selected-receipt,
.receipt-item:hover {
  border-radius: 15px;
  background-color: rgb(241, 244, 246);
}
.card-container .card {
  border-radius: 15px;
}
.card-container {
  overflow-y: scroll;
  height: 500px;
}
</style>
